[class*="btn-"] {
  @apply inline-block py-2 px-4 rounded-full font-medium text-center text-base cursor-pointer;

  &[disabled] {
    @apply opacity-25 cursor-default;
  }
}

.btn-primary { // TODO: remove this class from the application
  @apply bg-dark text-negative;
}

.btn-fill-green {
  @apply bg-success text-negative;
}

.btn-fill-orange {
  @apply bg-brand text-negative;
}

.btn-fill-yellow {
  @apply bg-warning text-negative;
}

.btn-fill-qc {
  @apply bg-qc text-negative;
}

.btn-danger, // TODO: remove this class from the application
.btn-fill-red {
  @apply bg-danger text-negative;
}

.btn-secondary, // TODO: remove this class from the application
[class*="btn-outline"] {
  @apply border-2;
}

.btn-outline-orange {
  @apply border-brand text-brand;
}

.btn-outline-green {
  @apply border-success text-success;
}

.btn-outline-white {
  @apply border-negative text-negative;
}

.btn-outline-slate {
  @apply border-primary text-primary;
}

.btn-secondary, // TODO: remove this class from the application
.btn-outline-dark {
  @apply border-dark;
}

.btn-outline-red {
  @apply border-danger text-danger;
}

.btn-outline-qc {
  @apply border-qc text-qc;
}

[class*="icon--"] {
  @apply inline-block;
}

.icon--before {
  @apply mr-1.5;
}

.icon--after {
  @apply ml-1.5;
}
