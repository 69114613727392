.pagy-nav {
  @apply inline-block p-2 mt-2 rounded lowercase bg-light;

  a {
    @apply no-underline;
  }

  .disabled, .active, a {
    @apply py-0.5 px-2;
  }

  .page:not(.disabled):hover,
  .page.active {
    @apply text-focus font-bold;
  }

  .page.disabled {
    @apply text-primary cursor-default;
  }
}