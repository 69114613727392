// required for @import "zest/components/typography";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Plus+Jakarta+Sans:wght@400;700;800&family=Anonymous+Pro:wght@700&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.todo {
  opacity: 0.5;
  border: 2px dotted red;
}

@layer utilities {
  .border-transparent {
    border-color: transparent;
  }
}

@layer base {
  body {
    @apply text-focus text-base; // this will need changing to base at text-primary but a lot of the app needs changing to factor this in
  }

  h1 {
    @apply text-xl font-bold;
  }

  h2 {
    @apply text-lg text-focus font-bold;
  }

  h3,
  .h3 {
    @apply text-focus font-bold;
  }

  h4 {
    @apply text-focus font-bold;
  }

  .meta {
    @apply text-sm uppercase opacity-75 tracking-wider;
  }
}

@layer components {
  .content {
    @apply p-4 md:p-8;
  }

  .header {
    @apply z-10;
  }

  .scrollable-container {
    .zen & {
      max-height: 100vh;
    }
  }

  .main-content-area {
    @media screen and (min-width: 1024px) {
      @apply overflow-auto;
    }
  }

  .aside {
    @apply lg:max-w-sm xl:max-w-2xl;
    @apply p-4 md:p-8 flex-1 bg-lighter overflow-auto;
  }

  .tabs {
    @apply flex border-b border-primary;

    .tab {
      @apply inline-block p-4;

      &--active {
        @apply border border-b-0 border-primary -mb-1 bg-default;
      }
    }
  }

  a > .pill {
    text-decoration: underline;
  }

  .live-indicator {
    @apply inline-block rounded-full bg-danger w-2 h-2;

    animation: pulse 1s infinite;
  }

  @import "components/form";
  @import "components/options";
  @import "components/table";
  @import "zest/components/typography";
  @import "zest/components/flow_tab";
  @import "zest/components/calendar";
  @import "zest/components/buttons";
}

@import "direct_uploads";
@import "pagy";
@import "trix";

// @import 'tippy.js/dist/tippy.css';
@import "flatpickr/dist/flatpickr.css";

@keyframes pulse {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
